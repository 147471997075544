export const jobData = {
	1: {
		position: "Inventory Specialist, Patient Assistance Program",
		location: "United States",
		duties: [
			"Overall responsibility for health components of the project",
			"Facilitate implementation of the workplan, manage timelines and activities to ensure that milestones are achieved in a timely manner",
			"Support the development of tools and delivery of trainings in the health component",
			"Establish and strengthen (where available) a favorable working relationship between WHW and leadership at the Regional Health Directorate, District Health Directorates and health facilities involved in the project",
			"Liaise between Women’s Health to Wealth and the District /Municipal Health to ensure that project requests, updates and all other relevant information are available",
			"Support health coordinator and regional/district health staff to plan and conduct health trainings and supervision visits and mentor WHW, regional/district staff, and health facility staff as needed during visits",
			"Lead, direct, appraise and supervise health staff involved in the project",
			"Ensure that internal and external reports on health activities are prepared and disseminated within deadlines",
			"Liaise with Americares HQ staff to coordinate health activities and health commodity procurement",
		],
		requirements: [
			"Clinical degree (medical doctor, midwife, nurse, or other relevant clinical degree) or master’s degree in medical science or other field relevant to implementing RMNCH programs",
			"A minimum of 5 years’ clinical practice experience including competency in key maternal and newborn clinical skills such as neonatal resuscitation and essential newborn care and/or A minimum of 5 years’ experience in the implementation of RMNCH and ASRH public health programs or projects with increasing management or leadership responsibilities",
			"Project cycle management experience and skills including assessment and planning, budget management, monitoring and evaluation",
			"Demonstrated experience in the implementation and management of RMNCH and ASRH public health programs or projects",
			"Ability to manage, organize, develop plans, and generate data to make decisions",
			"Experience with report writing and presenting findings.",
			"Excellent interpersonal skills and strong communications skills; ability to develop partnerships across sectors and cultures.",
			"Ability to work independently and as a part of a team, with a balanced focus on personal accountability and team goals.",
			"Strong organizational skills and attention to detail.",
			"Excellent writing skills and experience writing donor reports.",
			"Proficiency in Office 365 including Word, Excel, Teams, PowerPoint and SharePoint.",
		],
	},

	2: {
		position: "Nurse Practitioner, Adult",
		location: "Norwalk, United States",
		duties: [
			"Provide a thorough and appropriate medical exam and render proper care as required in the diagnosis and treatment of patients within the limits of experience and professional training.Provide appropriate patient instruction and/or consultation with family or significant other, as needed.",
			"Use of Athena Electronic Health Record to document examination, diagnosis, and treatment notes.Provide clear orders, and be available to nursing staff for direction, support, and clarification of the medical plan of care.",
			"Appropriately utilize diagnostic and laboratory services as required for quality care; identify those patients beyond our scope of care; make patient referrals to appropriate specialty services; determine medication needs utilizing available resources.",
			"Provide care to patient according to primary medical home model including following preventative care guidelines and participating in quality improvement activities.",
			"Serve as a consultant with regard to decisions pertaining to medical policy and patient care, and resolution of medical staff conflict and performance standards.",
			"Model exemplary professional behavior in a team building atmosphere respecting the roles and responsibilities of entire team",
			"Acknowledge that AFC maintains a policy and procedure manual at all locations and agree to follow the policies and procedures set forth within the manual",
		],
		requirements: [
			"US work authorization required",
			"At least two years of experience as NP providing care to adults with chronic disease.",
		],
	},
	3: {
		position: "Senior Associate",
		location: "Remote",
		duties: [
			"Manage assigned meetings to include scheduling, agenda circulation, taking and disseminating minutes",
			"Follow up on action points outlined at meetings, including aggregating document content from multiple teams, gathering and organizing information",
			"Track project deadlines and support the project team in meeting those deadlines",
			"Support team efforts with upcoming revamp of technology systems utilized organization-wide",
			"Assist with invoice process to facilitate payments",
			"Lead administrative support for assigned areas/initiatives",
			"Manage US Medicine Security inventory donations",
			"Support programming to include but not limited to partner communication (email and phone) and reporting",
			"Development of data dashboards for internal and external audiences",
			"Prepare information for staff to use with internal and external audiences, including fact sheets, training, and presentation materials",
			"Research sources of information on various health themes as well as other related topics, as required",
			"Support the development and implementation of processes and systems related to collecting, storing, and reporting programmatic data",
			"Assist in maintaining website content",
			"Assist in regular report preparation as needed",
			"Assist in other communications support as assigned",
			"Support/manage internal requests for US partners ",
		],
		requirements: [
			"3-5 years of progressive administrative support experience",
			"Microsoft Office proficiency, including Outlook, Word, Excel, PowerPoint, SharePoint.",
			"Experience with Tableau, or similar data visualization software",
			"Salesforce CRM experience",
			"Adept at using meeting and audiovisual technologies, especially Zoom and Teams",
			"Excellent time management and prioritization skills.",
			"Exceptional verbal and written communication skills.",
			"Excellent organizational skills and multitasking capacity",
			"Ability to work across various teams with diverse backgrounds and demonstrate cultural humility",
		],
	},
	4: {
		position: "Analyst, Financial Planning and Analysis",
		location: "Remote",
		duties: [
			"Prepare budget worksheets with comparative prior year information",
			"Work with budget holders to facilitate their completion of submissions",
			"Assist in the analysis and production of the budget package for management review",
			"Assist in monthly spread of budget worksheets and upload into system.",
			"Perform analytics of restricted time allocation and associated entries",
			"Track and analyze the monthly actual reports for assigned departments and large multi-department organizational projects to identify issues and opportunities versus budget and to assist in preparation of any needed adjustment entries",
			"Assist in review of Federal grant budget proposals and final budgets for system upload",
			"Assist in analysis for our annual financial audit, as needed",
			"Assist in the completion of other finance tasks, as assigned",
		],
	},
	5: {
		position: "Social Media Manager",
		location: "Remote",
		duties: [
			"Dive deep into TikTok trends to uncover opportunities for our influencer clients.",
			"Collaborate closely with influencers to craft creative content strategies tailored to their unique brand and audience.",
			"Provide clear and straightforward instructions for TikTok video recording.",
			"Foster a culture of loyalty, long-term commitment, and continuous improvement within the team.",
			"Work collaboratively with a team of 3-4 specialists, ensuring quality standards are met.",
			"Conduct regular quality checks on content and offer feedback for improvement.",
			"Handle client communication effectively and professionally, building strong relationships with our content creators.",
			"Stay up-to-date with TikTok and social media algorithm changes and industry trends.",
			"Craft engaging and effective copywriting for captions and content.",
		],
		requirements: [
			"Bursting with creativity and a passion for TikTok and social media.",
			"Professionalism and exact work is your second nature.",
			"Excellent written and spoken English; Spanish and Portuguese proficiency is a plus.",
			"Strong communication and client-facing skills.",
			"Background in marketing or film making preferred.",
			"Proficiency in using TikTok for private use.",
			"Ability to work both independently and as part of a team.",
			"Demonstrated loyalty and commitment, with a willingness to take on leadership roles within the company.",
			"Previous experience in managing a team is a plus.",
			"Social Media Management experience is a plus.",
			"Marketing background is a plus.",
			"Film making experience is a plus.",
			"A creative and analytical mindset.",
			"Enthusiasm for staying updated with social media and TikTok algorithm changes.",
			"Copywriting skills for crafting captivating captions.",
		],
	},
};
